import { FILE_BASE_URL, SYLLABUS_BASE_URL } from "../../../config";
import Image from "../../../../src/components/assets/HOD-CE.png";
import Dimg from "../../../../src/components/assets/pec_civil.jpg";

export const Civil = {
	basePath: "/ce",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			href: FILE_BASE_URL + "ce-research.html",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "Civil engineering",
		ShortName: "ce",
		LongName: "DEPARTMENT OF CIVIL ENGINEERING",

		DeptImg: Dimg,

		count: [
			{
				name: "B.Tech Students",
				count: 236,
			},
			{
				name: "M.Tech Students",
				count: 58,
			},
			{
				name: "Ph.D Scholars",
				count: 34,
			},
		],

		about: [
			`The Department of Civil Engineering was established during the inception of the college. Apart from offering B.Tech in Civil Engineering, two M.Tech programmes in Structural Engineering and Environmental Engineering specialization and PhD  programme in Civil Engineering are offered.`,
			`The major strength of this department is its faculty. Highly qualified, experienced and committed faculty members significantly contribute to the growth of the college in general and this department in particular. Students are offered world class training both in theory and practice. Industrial training is part of the curriculum both for UG and PG Students.`,
		],
		vision: [
			{
				point:
					"To create skilled and employable engineers through teaching, training and research to serve and provide sustainable solutions to the society with professional and ethical commitment.",
			},
		],

		Mission: [
			{
				point:
					"To provide a dynamic learning environment through innovative teaching methodologies and contemporary curriculum so as to prepare competitive students for successful profession",
			},
			{
				point:
					"To impart technical skills through rigorous laboratory practices hands on field training so as to prepare the students to face real world situations.",
			},
			{
				point:
					"To actively encourage and mentor students through academic and research activities to develop the ability to be creative and innovative, to face the challenges of rapidly changing technology and development.",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "ce-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
		],

		Hod: {
			name: "Dr. G. RAMAKRISHNA",
			designation: "Professor and Head",
			img: Image,
			phnNo: "98942 70298",
			email: "hod.civil@ptuniv.edu.in",
			content:
				"The department of Civil Engineering takes the lead role in producing excellent, technically clued-up professionals and entrepreneurs to the globally competitive world.  The learners are exposed to various fields such as Structures, Geotechnical, Environmental, Water resources and Surveying etc. for their   professional career development, higher education and Research work. The Department is bestowed with highly qualified faculty members and excellent infrastructure facilities. To bring synergy in its various activities, the department engrosses in research and consultancy works.  The department looks forward in exposing the students to face real-time challenges in Civil Engineering sectors and succeed in offering technological solutions for the betterment of the society.",
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/ce-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/ce-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/ce-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/ce-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/ce-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/ce-events.pdf",
		},
	],
	Research: [
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
	],
	curriculum: [
		{
			name: "B.Tech",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "1_BT_CE_2021.pdf",
				},
				{
					name: "AY 2018-19",
					link: SYLLABUS_BASE_URL + "1_BT_CE_1819.pdf",
				},
			],
		},
		{
			name: "M.Tech EE",
			desc: "A degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "9_MT_CE_EE_2021.pdf",
				},
			],
		},
		{
			name: "M.Tech SS",
			desc: "A degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "10_MT_CE_SE_2021.pdf",
				},
			],
		},
	],
	facilities: [],
};
