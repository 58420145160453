import { FILE_BASE_URL, getImageUrl, SYLLABUS_BASE_URL } from "../../../config";

import Image from "../../../../src/components/assets/CSE_HOD.jpg";

import al from "./facility/AL3.jpg";
import dc from "./facility/DC1.jpg";
import esl from "./facility/ESL1.jpg";
import hdd from "./facility/HDD3.jpg";
import isl from "./facility/ISL3.jpg";
import mp from "./facility/MP1.jpg";
import psl from "./facility/PSL2.jpg";
import rl from "./facility/RL1.jpg";
import sh from "./facility/SH1.jpg";
import wL from "./facility/WL2.jpg";
import wt from "./facility/WT1.jpg";

export const CSE = {
	basePath: "/cse",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			to: "/research",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "Computer science and engineering",
		ShortName: "CSE",
		LongName: "DEPARTMENT OF COMPUTER SCIENCE AND ENGINEERING",

		DeptImg: getImageUrl("cse.jpg"),

		count: [
			{
				name: "B.Tech Students",
				count: 502,
			},
			{
				name: "M.Tech Data Science Students",
				count: 17,
			},
			{
				name: "M.Tech Information Security Students",
				count: 17,
			},
			{
				name: "Ph.D Scholars",
				count: 63,
			},
			{
				name: "MCA Students",
				count: 57,
			},
		],

		about: [
			`The Department of Computer Science and Engineering was established in the year 1987 with the objective of imparting high quality education in the field of Computer Science. 
			It provides a comprehensive program that emphasizes on Advanced Data Structures and Algorithms, Advanced Software Design, Artificial Intelligence, Parallel and Distributed Systems, Graphics and Image Processing, Machine Learning, Internet of Things, Information Security, Digital Forensics, Big Data and Data Mining.`,

			`The department has competent and committed faculty which encourages students' involvement in various academic and co-curricular activities. The department has well equipped computer laboratories with more than 400 computers and a Research Laboratory to carry out research and project activities.  
			The department has the latest infrastructure facilities with high speed Internet, advanced software labs, hardware labs, seminar halls and class rooms equipped with LCD projectors. Students of all programmes can take project internship with different organizations. `,

			`This component has been included in the curriculum to provide exposure for the students to work in an organization environment, as well as a chance to apply the learning in solving real-world business problems. 
			The department has a long tradition of producing technically competent engineers since 1987 and it has renowned alumni occupying prominent positions in the industry, academia and research all over the world.
		    The students of the department have got placements in major companies like HP, ZOHO, HCL, NOKIA, INFOSYS, RELIANCE, CTS, L&T INFOTECH etc.The Department faculty are actively involved in various AICTE- RPS, UGC and SERB Sponsored Research Projects, Consultancy projects and Collaborative Projects with other government sectors.`,
		],

		vision: [
			{
				point:
					"To explore innovative approaches to enhance and expand learning opportunities through the integration of various technologies.",
			},
			{
				point:
					"To build a strong research and teaching environment that responds to the real-time challenges of the industry.",
			},
		],

		Mission: [
			{
				point:
					"To inculcate the blend of competence, aptitude of knowledge and investigate flair through devising an ambient environment for sustainable learning.",
			},
			{
				point:
					"To transform attitude, values, priorities by changing mindset and instill positive outlook for socially conscious intellectual development.",
			},
			{
				point:
					"Incubate, apply and spread innovative ideas to evolve the department as a centre of excellence in thrust areas.",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "cse-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
			{ point: "M.C.A" },
		],

		Hod: {
			name: "Dr. E. ILAVARASAN",
			designation: "Professor and Head",
			phnNo: "9443084714",
			email: "hod.cse@ptuniv.edu.in",
			content: `The Department of Computer Science and Engineering (CSE) is comprised of a
team of highly qualified, experienced, and motivated faculty members dedicated
to providing students with a solid foundation in technical knowledge and
problem-solving skills. This foundation equips students to achieve global
excellence in industry, academia, and research. The faculty members are the
greatest asset of the department, and their consistent contributions have
enabled the CSE Department to evolve and flourish as one of the most vibrant
and distinguished departments within the University.
All faculty members hold doctoral degrees and are experts in cutting-edge fields
such as High performance Computing, Artificial Intelligence, Machine Learning,
Deep Learning, Data Science, Network Technologies, Language Technology, Web
Technologies, Social Networks, Internet of Things, Cybersecurity, and Software
Engineering.
As part of its Industry-Institute Collaboration initiative, the CSE Department has
signed Memoranda of Understanding (MoUs) with various reputed industries.
These partnerships provide students with opportunities to work on internships
and projects, enabling them to develop technology solutions that impact industry
and society while enhancing their employability skills.

With a strong alumni network, the faculty and students actively engage in social
initiatives both individually and as part of various university clubs and
organizations. The department’s primary goal is to shape students into skilled
professionals and responsible citizens, contributing positively to society. `,
			img: Image,
		},
	},

	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/cse-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/cse-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/cse-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/cse-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/cse-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/cse-events.pdf",
		},
	],

	Research: [
		{
			name: "Specialization",
			shortContent: "",
			longContent:
				"Artificial Intelligence and Machine Learning, Computer Vision and Image Processing, Distributed Systems and Cloud Computing, Cyber Security and Privacy, Natural Language Processing (NLP) and Information Retrieval, Software Engineering and Programming Languages",
//			img: getImageUrl("res1.jpg"),
            img: "",
		},
		{
			name: "Highlights",
			shortContent: "",
			longContent:
				"The department has 21 research supervisors. It has produced 160 doctorates since 2006 and currently guiding 60+ full time and part time research scholars",
          img: getImageUrl("res2.jpg"),
		},
		{
			name: "Computer-assisted education",
			shortContent: "",
			longContent:
				"The use of computers and software to assist education and/or training, computer-assisted education brings many benefits and has many uses.",
			img: getImageUrl("res3.jpg"),
		},
		{
			name: "Bioinformatics",
			shortContent: "",
			longContent:
				"A fascinating application of big data, bioinformatics, or the use of programming and software development to build enormous datasets of biological information for research purposes, carries enormous potential.",
			img: getImageUrl("res4.jpg"),
		},
		{
			name: "Cyber security",
			shortContent: "",
			longContent:
				"According to the US Bureau of Labor Statistics, cyber security jobs are predicted to grow by 28 percent between 2016 and 2026 – much faster than average for all occupations, and raising concerns about the shortfall in qualified graduates. ",
			img: getImageUrl("res5.jpg"),
		},
	],
	curriculum: [
		{
			name: "B.Tech",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2018-19",
					link: SYLLABUS_BASE_URL + "4_BT_CS_1819.pdf",
				},
				{
					name: "AY 2014-15",
					link: SYLLABUS_BASE_URL + "4_BT_CS_1415.pdf",
				},
			],
		},
		{
			name: "M.Tech (Information Security)",
			desc: "MTech Computer Science is a two-year duration postgraduate level programme pursued in different specializations. Computer Science is a knowledge and practical skills based programme that imparts students with job ready computer science knowledge.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "16_MT_CS_IS_2021.pdf",
				},
			],
		},
		{
			name: "M.Tech (Data Science)",
			desc: "MTech Computer Science is a two-year duration postgraduate level programme pursued in different specializations. Computer Science is a knowledge and practical skills based programme that imparts students with job ready computer science knowledge.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "15_MT_CS_DS_2021.pdf",
				},
			],
		},
		{
			name: "M.C.A. (Computer Applications)",
			desc: "MCA full form Master of Computer Application is a postgraduate course that trains the students about the various aspects of computer programs, application software, computer architecture, operating systems and many more. The duration of the course is for a period of 2 years See Also: Full Form of Computer Application Courses.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "20_MC_2021.pdf",
				},
			],
		},
	],
	facilities: [
		{
			name: "Distributed Computing Lab",
			img: dc,
			content:
				"Intel Core i5 second generation 2400 @ 3.1Ghz 6 MB L3 cache 500 GB 7200 rpm Serial ATA HDD, 2 GB 1066 MHz DDR3 RAM, 18.5 inch Digital Color Monitor, 10/100/ 1000 Network Port. 10 KVA UPS with Exide SMF Batteries (one Hour Backup)",
		},
		{
			name: "Wireless LAN Lab",
			img: wL,
			content:
				"Intel i5 , RAM - 2GB DDR3 1333, Hard Disk 320 GB 7200 RPM SATA / 300 8.5 HDD SATA DVD writer 22x PS/2 key board block 3 button scroll optical USB mouse HCL led wide 18.5 Monitor with Speaker Intel Core i5 second generation 2400 @ 3.1Ghz 6MB L3 cache 500 GB 7200 rpm Serial ATA HDD, 2 GB 1066 MHz DDR3 RAM, 18.5 inch Digital Color Monitor, 10/100/ 1000 Network Port",
		},
		{
			name: "Problem Solving Lab",
			img: psl,
			content:
				"Desktop Computers: INFINITE LA390 PRO Intel Core i5, 2400@3.10 GHz 6MB, Intel H77 Chipset, 2GB DDR3, 500 GB Hard Disk, Accessories, 10/100/1000 On-board NIC Desktop Computers - Intel® Core™ i5-4570 Processor @ 3.2 GHz Processor, Intel chipset H81,4 GB DDR3 RAM , 500 GB HDD, 47 cm (18.5 inch) LED Digital Colour Monitor, 16X DVD writer, 1000 Mbps on board integrated network port Windows 8.1 Professional Licensed Desktop Computers - Intel® Core™ i3-4160 Processor @ 3.6 GHz Processor, 4 GB DDR3 RAM , 500 GB HDD, 47 cm (18.5 inch) LED Digital Color Monitor, 16X DVD writer, 1000 Mbps on board integrated network port Windows 8.1 Professional Licensed Preloaded 10 KVA UPS with Exide SMF Batteries (one Hour Backup)",
		},
		{
			name: "Hardware and Network Trouble Shooting Lab",
			img: hdd,
			content:
				"Desktop Computers - Intel® Core™ i5-4570 Processor @ 3.2 GHz Processor, Intel chipset H81,4 GB DDR3 RAM , 500 GB HDD, 47 cm (18.5 inch) LED Digital Colour Monitor, 16X DVD writer, 1000 Mbps on board integrated network port Windows 8.1 Professional Licensed Preloaded 10 KVA UPS with Exide SMF Batteries (one Hour Backup)",
		},
		{
			name: "Information Security Lab",
			img: isl,
			content:
				"HP 406 Series core I5- 4570 DSTP HP Intel core I5-4 th Gen Processor, 4GB DDR3 RAM, 500 GB HDD,DVDRW HP18.5” LED Monitor HP Usb Keyboard&amp; Mouse RestIntel Xeon processor E5 2600 v4 product family. Operating System Options: Microsoft Windows Server 2008 R2. Chipset: Intel C610 series chipset.",
		},
		{
			name: "Embedded Systems Lab",
			img: esl,
			content:
				"HCL Desktop Computers - Intel i5 , RAM - 2GB DDR3 1333, Hard Disk 320 GB 7200 RPM SATA / 300 8.5 HDD SATA DVD writer 22x PS/2 key board block 3 button scroll optical USB mouse HCL led wide 18.5 Monitor with speaker Red Hat V5.5 Media (10 Nos.) HP Desktop Computers - Intel Core i5 4570 @ 3.2 GHz, or Higher, 4 GB DDR3-1600 RAM, 500 GB 7200 RPM SATA Hard Disk or higher, 47cm (18.5 inch) LED Digital Colour monitor, 8X DVD RW or higher, Integrated Intel PRO 10/100/1000 Network (15 Nos.) ARM 7 Trainer Boards, ARM Cortex Trainer Boards Intel Galileo Development Boards, JLINK Debugger Interface kits such as, Matrix Graphic LCD Interface, Real, Time Clock Interface, I2C Interface Card, Temperature Transducer Measurement",
		},
		{
			name: "Microprocessor s and Microcontroller s Lab",
			img: mp,
			content:
				"8085 Microprocessor trainer kits, 8086 Microprocessor trainer kits, 8051Microcontroller trainer kits, Interface kits like Stepper motors, Traffic controllers, Elevators, Music synthesizers, Desktop computers.",
		},
		{
			name: "Data Analytics Lab",
			img: al,
			content:
				"Desktop Computers - Intel core i5 RAM-2GB DDR3, 320 GB, DVD writer Lx Infinite Pro Bl1280 5W Rms H1 Media Speakers-Black, HCL 3button W/Scroll Optical Mouse- HCL 101+14 button Int Keyboard Blk Vista Kit Free Dos Preloaded LX Infiniti Pro BL 1280, Intel G31 Chipset, Intel Core 2 Duo E7200 2.53GHz 1066MHz 3MB cache, 320 GB HDD, keyboard, mouse, DOS Preloaded 47cm HCL Wide LCD W/Speaker TCO Blk",
		},
		{
			name: "Research Lab",
			img: rl,
			content:
				"Intel Core i3- 4 th Gen Desktop. H81 Intel Chipset, Mother Board, 4GB DDR3 RAM, 500GB HDD, DVD, RW HP 18.5” LED Monitor HP USB Keyboard HP USB mouse Intel Core 2 Duo CPU E 7500 @ 2.93GHz 2.94GHz RAM 2.00GB HID- complaint mouse standard PS/2 Keyboard HCMEL WBTII monitor (HCL)",
		},
		{
			name: "Srinivasa Ramanujam Computer Centre",
			img: sh,
			content:
				"Desktop Computers - Intel Core 2 Duro E 7300 2.666 GHz 3MB Cache 1066 Mhz Fsb DDR2 RAM 667 Mhz Intel G 31 Chipset With Mother Board Optical Mouse 17&quot; TFT Monitor , OS Free Linux /Doss, Microsoft, Novel, HCL Systems Desktop Computers - Intel® Core™ i5-4570 Processor @ 3.2 GHz Processor, Intel chipset H81,4 GB DDR3 RAM , 500 GB HDD, 47 cm (18.5 inch) LED Digital Colour Monitor, 16X DVD writer, 1000 Mbps on board integrated network port Windows 8.1 Professional Licensed Preloaded (30 Nos)",
		},
		{
			name: "Web Technology Lab",
			img: wt,
			content:
				"HP Desktop Computer 10th Generation Intel® Core™ i5 processor 3.0 Ghz Windows 10 Home Single Language. 8 GB DDR4- 2666 MHz RAM (1 x 8 GB) 256 GB  SSD. 500GB 7200 rpm SATA HDD, Numeric KVA UPS BENQ- Digital Projector MS560P – SVGA(800X600) , DLP – Support for 1.1X, TR 1.96-2.15, HDMIx EPSON – PRINTER - M2170 Monochrome All-in- One Wi-Fi Duplex InkTank Printer(Black)",
		},
		{
			name: "Artificial Intelligence and Data Science Lab",
			img: al,
			content:
				"HP Desktop Computer 10th Generation Intel® Core™ i5 processor 3.0 Ghz Windows 10 Home Single Language. 8 GB DDR4-2666 MHz RAM (1 x 8 GB) 256 GB  SSD. 500GB 7200 rpm SATA HDD, Numeric KVA UPS HP Desktop Computerh Generation Intel® Core™ i5 processor 3.0 Ghzdows 10 Home Single Language.B DDR4-2666 MHz RAM (1 x 8 GB) 256 GB . 500GB 7200 rpm SATA HDD, Numeric KVA",
		},
	],
};
