import { FILE_BASE_URL, SYLLABUS_BASE_URL } from "../../../config";

import Image from "../../../../src/components/assets/EIE_HOD.jpg";

import Dimg from "../../../../src/components/assets/IT.jpg";

export const EIE = {
	basePath: "/eie",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			to: "/research",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "Electronics and Instrumentation Engineering",
		ShortName: "EIE",
		LongName: "DEPARTMENT OF Electronics and Instrumentation Engineering",

		DeptImg: Dimg,

		count: [
			{
				name: "B.Tech Students",
				count: 213,
			},
			{
				name: "M.Tech Students",
				count: 5,
			},
		],

		about: [
			`Instrumentation is the branch of engineering that deals with measurement, control and automation. In this fully automated world, this course which is a complete blend of technical subjects involving both Electronics and instrumentation areas seems to find its own place in providing the students with adequate knowledge and expertise. Electronics being the order of the day, it is very much necessary for the young minds to get accustomed with the latest developments in Electronics field. Moreover, a combination of Electronics and Instrumentation would fetch high dividends for the students who take up Electronics and Instrumentation as their career course. With the increasing demand for instrumentation engineers, the Department of Electronics and Instrumentation Engineering was started in 1998 and has been producing resourceful engineers for the past twenty years. The department has been accredited by AICTE, New Delhi.`,
			`The department has well experienced and highly-motivated faculty members who are specialised in different fields. The department offers UG (B.Tech in EIE), PG (M.Tech in Instrumentation Engineering) and Ph.D. in different areas of Instrumentation such as Biomedical, MEMS, Virtual Instruments and Embedded systems.`,
		],
		vision: [
			{
				point:
					"To produce world class industry ready Instrumentation Engineers and to establish state of art Instrumentation facilities to cater to the needs of the society",
			},
		],

		Mission: [
			{
				point:
					"To produce world class industry ready Instrumentation Engineers and to establish state of art Instrumentation facilities to cater to the needs of the society",
			},
			{
				point:
					"To increase the visibility of academic programs globally and attract talent at all levels.",
			},
			{
				point:
					"To undertake collaborative research / industrial projects through internship which offer opportunities for long term interaction with academia and industry.",
			},
			{
				point:
					"Sustained interaction with the alumni, students, parents, faculty and other stake holders to stay relevant in the globalized environment.",
			},
			{
				point:
					"To develop human potential to its fullest extent so that intellectually capable and imaginative gifted leaders can emerge in a range of professions ",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "eie-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
		],

		Hod: {
			name: "Dr. S. RAJENDIRAN",
			designation: "Professor and Head",
			img: Image,
			// phnNo: "",
			email: "hod.eie@ptuniv.edu.in",
			content: `The Department of Electronics and Instrumentation Engineering UG Programme is established in 1998, with an intake capacity of 30 students. In 2012 the intake capacity is increased to 60 students and one PG programme Instrumentation engineering. The Department has qualified and experienced faculty with Life Membership of various bodies such as ISA, ISTE, Biomedical Society of India, Instrument Society of India, The Department is also well equipped with laboratory facility for the academic development of the students, Viz. Process Control lab, Process Measurement lab, Microprocessor & Electronics lab, Virtual instrumentation lab and Embedded design lab etc. The Department has well maintained computer systems and multimedia projector especially for all class room virtual instrumentation laboratory.  Plenty of job opportunities are available for students in various Process Industries and also in Government sector. This is a highly specialized inter disciplinary course having Wide job opportunities in India and abroad in industries such as Automation, Electronics, Biomedical Instrumentation, automobile, power, steel, fertilizer, chemical, refineries, cement manufacturing, sugar plants, etc.`,
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/eie-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/eie-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/eie-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/eie-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/eie-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/eie-events.pdf",
		},
	],
	Research: [
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
	],
	curriculum: [
		{
			name: "B.Tech",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "BT_EI_2021.pdf",
				},
			],
		},
		{
			name: "M.Tech",
			desc: "A degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "MT_EI_2021.pdf",
				},
			],
		},
	],
	facilities: [
		{
			name: "Electronic Devices and Circuits Lab",
			img: "",
			content:
				" practical oriented electronic circuits that are very essential for the students to solidify their theoretical concepts. This Manual provides a communication bridge between the theory and practical world of the electronic circuits.",
		},
		{
			name: "Microprocessor Lab",
			img: "",
			content:
				"The purpose of this laboratory is to train the students to be familiar with the software and hardware of microprocessors so that they can gain enough experiences to meet the demand of the microprocessor era. The facilities in the laboratory enable students to build a firm background in microprocessor hardware as well as software .",
		},
		{
			name: "Embedded System Design Lab",
			img: "",
			content:
				"Embedded Systems Design Lab The ESD Lab is used to teach students to design and simulate digital circuits. The lab includes Oscilloscopes, Arbitrary Function Generators, Power Supplies and Multimeters. Many high tech software packages are installed to allow students to create embedded systems.",
		},
		{
			name: "Sensors & Transducers Lab",
			img: "",
			content:
				"The word “Transducer” is the collective term used for both Sensors which can be used to sense a wide range of different energy forms such as movement, electrical signals, radiant energy, thermal or magnetic energy, etc., and Actuators which can be used to switch voltages or currents.",
		},
		{
			name: "Process Control Lab",
			img: "",
			content:
				"Multi Process trainer, Multi variable Laboratory type Level Process, Calibration test bench, PLC trainer, Distributed Control system, Control Valve Characteristics Setup,",
		},
		{
			name: "Virtual Instrumentation Lab",
			img: "",
			content:
				"Virtual instrumentation is the use of customizable software and modular measurement hardware to create user-defined measurement systems, called virtual instruments. Traditional hardware instrumentation systems are made up of fixed hardware components, such as digital multimeters and oscilloscopes that are completely specific to their stimulus, analysis, or measurement function. Because of their hard-coded function, these systems are more limited in their versatility than virtual instrumentation systems.",
		},
	],
};
